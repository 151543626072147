.App {
  text-align: center;
}

.App-logo {
  width: 300px;
  pointer-events: none;
}

.App-header {
  background-color: #323f4f;
  min-height: 100vh;
  background-size: cover;
  /*background*/
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/*.App-header.bg-yulp {*/
/*  background-image: url("../public/login-bg.jpg");*/
/*}*/
/**/
/*.App-header.bg-bleds {*/
/*  background-image: url("login-bg-bleds.jpg");*/
/*}*/
/**/
/*.App-header.bg-bleds > .login-form-wrapper {*/
/*  background-image: url("login-bg-bleds.jpg");*/
/*}*/

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.login-form-wrapper {
  text-align: center;
  width: 600px;
  /*background-color: white;*/
  opacity: 0.8;
  background-color: #323f4f;
  padding: 50px;
  border: 0px solid #69bccf;
  border-radius: 2px;
  color: #fff;
}

.login-form {
  margin: 0 auto;
  width: 300px;
}

#components-form-demo-normal-login .login-form {
  max-width: 300px;
}
#components-form-demo-normal-login .login-form-forgot {
  float: right;
}
#components-form-demo-normal-login .login-form-button {
  width: 100%;
}

.ant-layout-sider-dark, .ant-menu-dark, .ant-layout-header {
  background-color: #323f4f;
}

.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
  background-color: #323f4f;
}

.ant-tabs { overflow: unset; }

.ant-table-cell-fix-left {
  background: white !important;
}
